import { containerPadding } from '../PlayContainer/PlayContainer';
export const defaultSwipeableConfig = {
  itemCount: 4.3,
  spaceBefore: 0,
  spaceBetween: 16
};
export const defaultViewportSwipeableConfig = [['small', {
  itemCount: 1.55,
  spaceBefore: containerPadding.small,
  spaceBetween: 8
}], ['medium', {
  itemCount: 3.2,
  spaceBefore: containerPadding.medium,
  spaceBetween: 16
}], ['large', {
  itemCount: 3.2,
  spaceBefore: containerPadding.large,
  spaceBetween: 16
}], ['xLargeUp', defaultSwipeableConfig]];
export const defaultPosterViewportSwipeableConfig = [['small', {
  itemCount: 2.55,
  spaceBefore: containerPadding.small,
  spaceBetween: 8
}], ['medium', {
  itemCount: 3.2,
  spaceBefore: containerPadding.medium,
  spaceBetween: 16
}], ['large', {
  itemCount: 5.3,
  spaceBefore: containerPadding.large,
  spaceBetween: 16
}], ['xLargeUp', {
  itemCount: 6.5,
  spaceBefore: 0,
  spaceBetween: 16
}]];