import { styled, css } from 'styled-components';
import { hideScrollbar } from '../../../../play-core/styles/style-helper';
import { Viewports } from '../../../../play-core/styles/Viewports';
export const ContentContainer = styled.div.withConfig({
  displayName: "Swipeableelements__ContentContainer",
  componentId: "sc-14k0fh5-0"
})(["position:relative;overflow:hidden;"]);
export const ScrollContainer = styled.div.withConfig({
  displayName: "Swipeableelements__ScrollContainer",
  componentId: "sc-14k0fh5-1"
})(["overflow:scroll;margin:0;display:flex;flex-shrink:0;", " ", ";"], hideScrollbar, props => swipeableItemSize(props.config));
const swipeableItemSize = viewportConfig => viewportConfig.map(([viewport, config]) => css(["", "{> *{margin-left:", "px;min-width:", ";max-width:", ";}> :first-child{margin-left:", "px;}}"], Viewports.for(viewport), config.spaceBetween, itemWidth(config), itemWidth(config), config.spaceBefore));
const itemWidth = ({
  itemCount,
  spaceBefore,
  spaceBetween
}) => `calc((100% - ${spaceBefore}px - ${spaceBetween}px * ${itemCount}) / ${itemCount})`;