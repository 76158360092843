import { useEffect, useState } from 'react';
import { useAnalyticsDispatch } from './AnalyticsContext';
import { calculateScrollTrackingStep } from './calculate-scroll-tracking-step';
export const useHorizontalScrollTracker = (name, xPosition, containerWidth, scrollWidth) => {
  const analytics = useAnalyticsDispatch();
  const [lastTracked, setLastTracked] = useState();
  useEffect(() => {
    const step = calculateScrollTrackingStep(xPosition, scrollWidth, containerWidth, lastTracked);
    if (name && step) {
      setLastTracked(step);
      analytics({
        type: 'click-event',
        name: 'HorizontalScroll',
        value1: name,
        value2: `${step}`
      });
    }
  }, [name, xPosition, containerWidth, scrollWidth, lastTracked, analytics]);
};